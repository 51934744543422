import React, { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { postApiRequest } from "../../../../apiHandler";
import { useState } from "react";
import { formatPrice } from "../../../../utils/utils";

export default function InformationDeLannonce({
  formData,
  setFormData,
  setRef,
}) {
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [prediction, setprediction] = useState(false);
  const [predictedPrice, setpredictedPrice] = useState({
    lower_price: 0,
    predicted_price: 0,
    rent_predict_price: 0,
    upper_price: 0,
  });

  const formatPriceWithSpaces = (value) => {
    const numericValue = String(value)?.replace(/[^\d]/g, "");
    return numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "price") {
      const inputPrice = parseFloat(value.replace(/[\s,]/g, ""));
      const suspiciousCheck = isPriceUnusual(
        inputPrice,
        predictedPrice.predicted_price
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        suspiciousPrice: suspiciousCheck,
      }));
    }
    setCheck(true);
    const sanitizedValue =
      name === "title" || "description" ? value : value.replace(/[^\d]/g, "");
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: sanitizedValue,
      original_price: prevFormData.price,
    }));
  };

  const excludedTypes = ["appartement", "immeuble", "bureau", "studio"];

  const generateDescriptionWithAI = async () => {
    setLoading(true);
    try {
      const generateDescriptionRes = await postApiRequest(
        "/api/ads/generate-description",
        formData
      );
      const { message, status } = generateDescriptionRes;
      if (status === 200) {
        setCheck(true);
        setFormData((prevFormData) => ({
          ...prevFormData,
          description: message,
        }));
      }
    } catch (error) {
      console.error("Error fetching description from API:", error);
    }
    setLoading(false);
  };

  const estimatedPrice = async () => {
    // console.log("estimation price: ", formData);
    setprediction(true);
    try {
      const estimatePrice = await postApiRequest(
        "/api/estimate-price-prediction",
        formData
      );
      const { status } = estimatePrice;
      if (status === 200) {
        setpredictedPrice(estimatePrice.response);
        if (formData.price) {
          const inputPrice = parseFloat(formData.price.replace(/[\s,]/g, ""));
          const suspiciousCheck = isPriceUnusual(
            inputPrice,
            estimatePrice.response.predicted_price
          );
          setCheck(true);
          setFormData((prevFormData) => ({
            ...prevFormData,
            suspiciousPrice: suspiciousCheck,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching prediction price:", error);
    }
    setprediction(false);
  };

  useEffect(() => {
    if (!check) {
      estimatedPrice();
    } else {
      setCheck(false);
    }
  }, [formData]);

  const isPriceUnusual = (userInputPrice, predictedPrice) => {
    if (predictedPrice !== 0) {
      const lowerBound = predictedPrice * 0.4;
      const upperBound = predictedPrice * 1.6;

      if (userInputPrice < lowerBound || userInputPrice > upperBound) {
        console.log("unusual range");
        return true;
      } else {
        console.log("usual range");
        return false;
      }
    }
    console.log("usual range");
    return false;
  };

  return (
    <Box>
      <Typography
        variant="body1"
        fontSize={32}
        fontWeight={600}
        color={"#263238"}
        gutterBottom
      >
        Information de l’annonce
      </Typography>
      <Typography variant="body1" fontSize={16} color={"#263238"} gutterBottom>
        Une annonce avec un prix exacte et une description bien détaillé a 10
        fois de visibilité.
      </Typography>
      <Card
        sx={{
          padding: "24px 28px",
          border: "none",
          background: "#fff",
          marginTop: "14px",
          borderRadius: "16px",
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
            lg: "row",
          },
          justifyContent: "space-between",
          alignItems: "center",
          boxShadow: "none",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#263238",
              fontWeight: 700,
              fontSize: "20px !important",
            }}
            variant="h5"
          >
            Estimation du prix
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#263238",
              fontSize: "14px !important",
              marginTop: "15px",
            }}
          >
            En fonction des informations que vous fournissez sur votre
            propriété, voici la valeur marchande estimée. Cette prévision est
            calculée sur la base des informations saisies ci-dessus sur la
            propriété
          </Typography>
        </Box>
        <Box
          textAlign="center"
          mt={{ xs: 2, sm: 2, md: 0, lg: 0 }}
          width={"100%"}
        >
          <Typography
            variant="body1"
            sx={{
              whiteSpace: "nowrap",
              background: "#EDF1F5",
              padding: "22px 20px",
              marginLeft: {
                xs: "0px",
                sm: "0px",
                md: "25px !important",
                lg: "25px !important",
              },
              fontSize: "26px !important",
              fontWeight: 700,
              borderRadius: "16px",
              color: "#0066EE",
            }}
          >
            {!prediction ? (
              formatPrice(parseInt(predictedPrice.predicted_price))
            ) : (
              <CircularProgress
                sx={{
                  width: "24px !important",
                  height: "24px !important",
                }}
              />
            )}
          </Typography>
        </Box>
      </Card>
      <Grid
        component="form"
        autoComplete="off"
        sx={{
          mt: 4,
          "& .MuiOutlinedInput-root": {
            borderRadius: "22.5px",
            backgroundColor: "#FFFFFF",
            "& fieldset": {
              borderColor: "rgba(132, 159, 186, 0.45)",
            },
            "&:hover fieldset": {
              borderColor: "rgba(132, 159, 186, 0.45)",
            },
            "&.Mui-focused fieldset": {
              borderColor: "rgba(132, 159, 186, 0.45)",
            },
          },
        }}
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid
          item
          xs={!excludedTypes.includes(formData["property_type"]) ? 12 : 6}
        >
          <FormControl variant="outlined" fullWidth>
            <Typography variant="body1" sx={{ fontSize: "12px", mb: 0.5 }}>
              Prix
              {formData.ads_type === "sale"
                ? " de vente"
                : formData.ads_type === "rent" && " de location"}
              <span style={{ color: "#EC544B" }}>*</span>
            </Typography>
            <TextField
              ref={setRef("price")}
              placeholder="Exemple : 1 000 000"
              id="outlined-start-adornment"
              name="price"
              value={formatPriceWithSpaces(formData?.price)}
              type="text"
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">DH</InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: 45,
                  borderRadius: "22.5px",
                  "& fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                },
              }}
            />
          </FormControl>
        </Grid>
        {!excludedTypes.includes(formData["property_type"]) ? (
          ""
        ) : (
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <Typography variant="body1" sx={{ fontSize: "12px", mb: 0.5 }}>
                Frais de syndic / mois
              </Typography>
              <TextField
                placeholder="Exemple : 200"
                id="outlined-start-adornment"
                name="trustee_fees"
                value={formatPriceWithSpaces(formData?.trustee_fees) || ""}
                onChange={handleInputChange}
                type="text"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">DH / Mois</InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: 45,
                    borderRadius: "22.5px",
                    "& fieldset": {
                      borderColor: "rgba(132, 159, 186, 0.45)",
                    },
                    "&:hover fieldset": {
                      borderColor: "rgba(132, 159, 186, 0.45)",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "rgba(132, 159, 186, 0.45)",
                    },
                  },
                }}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} mt={1.5}>
          <FormControl variant="outlined" fullWidth>
            <Typography variant="body1" sx={{ fontSize: "12px", mb: 0.5 }}>
              Titre de l’annonce
              <span style={{ color: "#EC544B" }}>*</span>
            </Typography>
            <TextField
              ref={setRef("title")}
              placeholder="Titre de l’annonce"
              id="outlined-start-adornment"
              name="title"
              value={formData.title || ""}
              onChange={handleInputChange}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: 45,
                  borderRadius: "22.5px",
                  "& fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} mt={1.5}>
          <FormControl variant="outlined" fullWidth>
            <Typography variant="body1" sx={{ fontSize: "12px", mb: 0.5 }}>
              Description
              <span style={{ color: "#EC544B" }}>*</span>
            </Typography>
            <TextField
              ref={setRef("description")}
              fullWidth
              multiline
              rows={4}
              placeholder="Entrez votre description"
              name="description"
              value={formData.description || ""}
              onChange={handleInputChange}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "16px",
                  "& fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "& textarea::-webkit-scrollbar": {
                    width: "0.6vh !important",
                  },
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} mt={1.5}>
          <Button
            fullWidth
            variant="outlined"
            disabled={loading}
            onClick={generateDescriptionWithAI}
            sx={{
              minWidth: { xs: "85%", sm: "150px" },
              minHeight: { xs: "85%", sm: "56px" },
              px: { xs: 3, sm: 5 },
              my: 2,
              fontFamily: `"Inter",sans-serif`,
              fontSize: 16,
              fontWeight: 500,
              borderRadius: "28px",
              border: "none !important",
              color: "#FFFFFF",
              background: "#0066EE",
              boxShadow: "none",
              "&:hover": {
                background: "#0066EE",
                color: "#FFFFFF",
              },
              "&:disabled": {
                background: "#d1dce6",
              },
            }}
          >
            {loading ? "Chargement..." : "Générer une description avec l'IA."}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
