import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  useMediaQuery,
  Container,
} from "@mui/material";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFinal from "./StepFinal";
import { MdOutlineLogout } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { getApiRequest, postApiRequest } from "../../apiHandler";
import Layout from "../../layout/Layout";
import "./index.css";
import { formatPriceArea } from "../../utils/utils";
import { selectUser } from "../../Redux/reducer/reducer";
import { useSelector } from "react-redux";

export default function MesAnnounces() {
  const { state } = useLocation();
  const defaultData =
    state && state?.annonceEditData.publish_ads
      ? { ...state.annonceEditData.publish_ads, editingData: true }
      : {};
  const [formData, setFormData] = useState(defaultData);
  const [activeStep, setActiveStep] = useState(0);
  const user = useSelector(selectUser);
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down(900));
  const [isEmailVerified, setIsEmailVerified] = useState(
    user?.email_verification
  );
  const refs = useRef({});

  const setRef = (name) => (el) => {
    if (el) {
      refs.current[name] = el;
    }
  };

  const scrollToElement = (name) => {
    const element = refs.current[name];
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      const input = element.querySelector("input, textarea, select");
      if (input) {
        input.focus();
      }
    }
  };

  useEffect(() => {
    state && state.step && setActiveStep(state.step);
  }, [state]);

  const submitData = async (check) => {
    let finalAdsData = { ...formData };
    if (!formData?.editingData) {
      const now = new Date();
      const millisecondsSinceEpoch = now.getTime();
      const price_m2 = formatPriceArea(
        parseInt(formData.price / formData?.area)
      );
      const updatedFinalData = generateFinalData(
        now,
        millisecondsSinceEpoch,
        price_m2
      );
      finalAdsData = createFormData(updatedFinalData);
    } else {
      finalAdsData = createFormData(finalAdsData);
    }

    try {
      if (!user.email_verification && !check) {
        await sendOtp();
        handleClick("next");
      } else {
        const adsPostedSuccessData = await postApiRequest(
          "/api/ads",
          finalAdsData
        );
        if (adsPostedSuccessData?.status === 200) {
          !check && user.email_verification && handleClick("next");
        } else {
          toast.error(adsPostedSuccessData?.message);
        }
      }
    } catch (error) {
      console.error("Ads submitting data:", error);
    }
  };

  const createFormData = (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === "geo_location") {
        formData.append(key, JSON.stringify(value));
      } else if (key === "s3_images_url" && Array.isArray(value)) {
        value.forEach((file) => {
          formData.append(`s3_images_url`, file);
        });
      } else {
        formData.append(key, value);
      }
    });
    return formData;
  };

  
  const generateFinalData = (now, millisecondsSinceEpoch, price_m2) => {
    const data = {
      user_id: user && user._id,
      id: millisecondsSinceEpoch,
      commune: formData.commune || null,
      province: null,
      region: formData.region || null,
      region_code: formData.region_code || null,
      city: formData.city || null,
      country: formData.country || null,
      quartier: null,
      address: formData.address || null,
      latitude: formData.latitude || null,
      longitude: formData.longitude || null,
      location: formData.location || null,
      geo_location: formData.geo_location || null,
      ads_type: formData.ads_type || "sale",
      property_type: formData.property_type || null,
      area: formData.area || null,
      area_unit: "m²",
      zoning: formData.zoning || null,
      age_of_property: formData.age_of_property || null,
      features: formData.features || null,
      condition_of_property: formData.condition_of_property || null,
      rooms: formData.rooms || null,
      bathrooms: formData.bathrooms || null,
      fairs: formData.fairs || null,
      floors_level: formData.floors_level || null,
      floors_count: formData.floors_count || null,
      duplex: formData.duplex || false,
      service_room: formData.service_room || false,
      not_overlooked: formData.not_overlooked || false,
      furnished: formData.furnished || false,
      terrace: formData.terrace || false,
      elevator: formData.elevator || false,
      balcony: formData.balcony || false,
      parking: formData.parking || false,
      secure_residence: formData.secure_residence || false,
      pool: formData.pool || false,
      solar_water_heater: formData.solar_water_heater || false,
      garage: formData.garage || false,
      garden: formData.garden || false,
      closed_residence: formData.closed_residence || false,
      surveillance_camera: formData.surveillance_camera || false,
      playground: formData.playground || false,
      basement: formData.basement || false,
      phone_code: String(formData.phone_code) || "+212",
      phone_number: formData.phone_number || null,
      price: formData.price || null,
      original_price: formData.original_price || null,
      currency: "DH",
      trustee_fees: formData.trustee_fees || null,
      title: formData.title || null,
      description: formData.description || null,
      s3_images_url: formData.s3_images_url || [],
      imagehash: formData.imagehash || [],
      habitable_surface: null,
      images_url: [],
      agency_details: null,
      agency: false,
      available: true,
      air_conditioner: false,
      gym: false,
      floors: false,
      url: null,
      website: "ki.ma",
      page_url: null,
      last_checked: now.toISOString(),
      last_modified: now.toISOString(),
      created_at: now.toISOString(),
      price_m2: price_m2.replace(/[^\d.]/g, ""),
      virtual_visit: formData.virtual_visit || null,
      suspiciousPrice: formData.suspiciousPrice || false,
    suspiciousImg: formData.suspiciousImg || false,
    termsAndConditions: formData.termsAndConditions || false,
    };
    
    return data;
  };
  const handleClick = (direction) => {
    setActiveStep((prevStep) =>
      direction === "next" ? prevStep + 1 : prevStep - 1
    );
  };

  const steps = [
    {
      title: "Emplacement",
      subTitle: "Ajouter l’adresse principale de l'annonce",
      form: (
        <StepOne
          formData={formData}
          setFormData={setFormData}
          handleClick={handleClick}
        />
      ),
    },
    {
      title: "Détails de l’annonce",
      subTitle: "Ajouter plus de détails de votre annonce",
      form: (
        <StepTwo
          formData={formData}
          setFormData={setFormData}
          handleClick={handleClick}
          setRef={setRef}
          scrollToElement={scrollToElement}
        />
      ),
    },
    {
      title: "Photos de l’annonce",
      subTitle: "Ajouter des photos de bonne qualité",
      form: (
        <StepThree
          formData={formData}
          setFormData={setFormData}
          handleClick={handleClick}
          submitData={submitData}
          setRef={setRef}
          scrollToElement={scrollToElement}
        />
      ),
    },
  ];

  const sendOtp = async () => {
    try {
      await getApiRequest(`/api/send-email-otp/${user?._id}`);
      return true;
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  return (
    <Layout check={true} mobilePosition={"relative"}>
      <Box my={3}>
        <Box
          display={"flex"}
          flexDirection={{
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
          }}
        >
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              paddingLeft: "0 !important",
              paddingRight: "0 !important",
            }}
          >
            <Box
              className="stepper_box"
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingX: "10px !important",
              }}
            >
              <Stepper
                activeStep={activeStep}
                connector={null}
                sx={{ display: "flex" }}
              >
                {steps.map(({ title, subTitle }, index) => (
                  <Step
                    key={index}
                    sx={{
                      paddingLeft: "16px",
                      paddingRight: "16px",
                    }}
                  >
                    <StepLabel
                      sx={{
                        "& .MuiStep-root .MuiStepLabel-root ": {
                          display: isMobileScreen && "none",
                        },
                        "& .MuiStepIcon-root": {
                          color:
                            index <= activeStep
                              ? "#0066EE !important"
                              : "#DAE3EB",
                          width: 32,
                          height: 32,
                          display: isMobileScreen && "none",
                        },
                        "&.MuiStepLabel-label": {},
                        "& .MuiStepIcon-text": {
                          fill: index <= activeStep ? "#FFFFFF" : "#a4aeb5",
                          fontSize: 12,
                          lineHeight: "19.36px",
                          fontFamily: "Inter, sans-serif",
                        },
                      }}
                    >
                      <>
                        <Typography
                          display={isMobileScreen && "none"}
                          variant="body1"
                          fontSize={14}
                          fontWeight={600}
                          color={index <= activeStep ? "#0066EE" : "#849FBA"}
                          gutterBottom
                        >
                          {title}
                        </Typography>

                        <Typography
                          display={isMobileScreen && "none"}
                          variant="body1"
                          fontSize={12}
                          color={index <= activeStep ? "#263238" : "#bec2c3"}
                          gutterBottom
                        >
                          {subTitle}
                        </Typography>
                      </>
                    </StepLabel>
                    <Box
                      sx={{
                        width: {
                          xs: "26vw",
                          sm: "26.5vw",
                          md: "100%",
                          lg: "100%",
                        },
                        height: 4,
                        mt: 2,
                        backgroundColor: "#E0E0E0",
                        borderRadius: 5,
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          height: "100%",
                          width: "100%",
                          backgroundColor:
                            index <= activeStep ? "#0066EE" : "transparent",
                          borderRadius: 5,
                          transition: "width 0.3s ease",
                        }}
                      />
                    </Box>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Container>

          <Box
            display={"flex"}
            justifyContent={isMobileScreen ? "space-between" : "flex-end"}
            mt={2}
          >
            <Box
              component={Link}
              to={"/"}
              display={isMobileScreen ? "flex" : "none"}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
              pl={"4vw"}
            >
              <Typography
                variant="body1"
                fontSize={16}
                fontWeight={600}
                color={"#263238"}
                mr={0.5}
              >
                {activeStep < 5 && `${activeStep + 1}/${steps.length}`}
              </Typography>
            </Box>
            <Box
              component={Link}
              to={"/"}
              display={"flex"}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
              pr={"4vw"}
            >
              <Typography
                variant="body1"
                fontSize={16}
                fontWeight={600}
                color={"#263238"}
                mr={0.5}
              >
                Quitter
              </Typography>
              <MdOutlineLogout color={"#263238"} size={19} />
            </Box>
          </Box>
          <Box
            component={Link}
            to={"/"}
            display={isMobileScreen ? "flex" : "none"}
            alignItems={"center"}
            sx={{ cursor: "pointer" }}
            pl={"4vw"}
          >
            <Typography
              variant="body1"
              fontSize={16}
              fontWeight={600}
              color={"#0066EE"}
              mr={0.5}
            >
              {activeStep < 3 && steps[activeStep].title}
            </Typography>
          </Box>
        </Box>
        {/* </Box> */}
        {/* Middle Content Section */}
        <Box
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          {activeStep === steps.length ? (
            <StepFinal
              formData={formData}
              user={user}
              submitData={submitData}
              isEmailVerified={isEmailVerified}
              setIsEmailVerified={setIsEmailVerified}
              sendOtp={sendOtp}
            />
          ) : (
            <TransitionGroup style={{ width: "100%" }}>
              <CSSTransition
                key={activeStep}
                classNames="step"
                style={{ display: "flex", justifyContent: "center" }}
                timeout={{ enter: 200, exit: 0 }}
              >
                <Box>{steps[activeStep].form}</Box>
              </CSSTransition>
            </TransitionGroup>
          )}
        </Box>
      </Box>
    </Layout>
  );
}
