import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Grid,
} from "@mui/material";
import PublishCard from "./PublishCard";
import mapboxgl from "mapbox-gl";
import { toast } from "react-toastify";
import "mapbox-gl/dist/mapbox-gl.css";
import { MapMarker } from "../../../component/ConstantImagesURL";
import axios from "axios";
import StepOneAutoComplete from "./StepOneAutoComplete";
import MapControls from "./MapControls";
import { estimationAddressMapping } from "../../../utils/utils";
import { TbCurrentLocation } from "react-icons/tb";
import { postApiRequest } from "../../../apiHandler";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

export default function StepOne({ formData, setFormData, handleClick }) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.up("900"));
  const mapRefContainer = useRef(null);
  const markerRef = useRef(null);
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState([-6.27614, 31.7917]);
  const [zoomLevel, setZoomLevel] = useState(5);
  const [address, setAddress] = useState(formData?.address || "");
  const [hasFlown, setHasFlown] = useState(false);
  const [mapStyle, setMapStyle] = useState(true);

  // Fixed bounds for Morocco
  const moroccoBounds = [
    [-17.2551456, 21.3365321], // Southwest coordinates (left bottom corner of Morocco)
    [-0.998429, 36.0505269], // Northeast coordinates (right top corner of Morocco)
  ];

  useEffect(() => {
    if (formData?.address) {
      setAddress(formData.address);
      if (formData?.lat && formData?.long) {
        const newCenter = { lat: formData.latitude, lng: formData.longitude };
        setCenter(newCenter);
        if (map && !hasFlown) {
          map.flyTo({
            center: [formData.longitude, formData.latitude],
            zoom: formData.street_check ? 17 : 18,
          });
          setZoomLevel(18);
          setHasFlown(true);
          if (markerRef.current) markerRef.current.remove();
          const newMarker = createCustomMarker([
            formData.longitude,
            formData.latitude,
          ]);
          newMarker.addTo(map);
          markerRef.current = newMarker;
        }
      }
    }
  }, [formData, map, hasFlown]);

  useEffect(() => {
    if (mapRefContainer.current && !map) {
      const initialCenter =
        formData.latitude && formData.longitude
          ? [formData.longitude, formData.latitude]
          : center;
      const initialZoom =
        formData.latitude && formData.longitude ? 18 : zoomLevel;
      const initializeMap = new mapboxgl.Map({
        container: mapRefContainer.current,
        style: "mapbox://styles/kifwatimmo/clztg3vho00gb01qtayvohg5e",
        center: initialCenter,
        zoom: initialZoom,
        locale: "fr",
        maxBounds: moroccoBounds,
        maxZoom: 18,
        minZoom: 5,
      });
      initializeMap.addControl(
        new mapboxgl.NavigationControl(),
        "bottom-right"
      );
      if (formData.latitude && formData.longitude) {
        const newMarker = createCustomMarker([
          formData.longitude,
          formData.latitude,
        ]);
        newMarker.addTo(initializeMap);
        markerRef.current = newMarker;
      }
      initializeMap.on("click", (e) => {
        const { lng, lat } = e.lngLat;
        handleMapClick(lng, lat, initializeMap);
      });
      initializeMap.on("moveend", () => {
        const { lng, lat } = initializeMap.getCenter();
        if (markerRef.current) markerRef.current.remove();
        const newMarker = createCustomMarker([lng, lat]);
        newMarker.addTo(initializeMap);
        markerRef.current = newMarker;
        getAddressFromLatLng(lng, lat, true);
      });
      setMap(initializeMap);
    }
  }, [map, formData, center, zoomLevel]);

  // GOOGLE :
  const handleAddressSelect = (selectedLocation) => {
    const { lat, lng, fullAddress, place } = selectedLocation;
    if (isLocationInsideMoroccoBounds(lng, lat)) {
      updateMarkerAndAddress(lng, lat, fullAddress, place, map);
    } else {
      toast.error(
        "Vous ne pouvez sélectionner que des adresses à l'intérieur du Maroc."
      );
    }
  };

  const handleMapClick = (lng, lat, mapInstance) => {
    if (isLocationInsideMoroccoBounds(lng, lat)) {
      updateMarkerAndAddress(lng, lat, null, null, mapInstance);
    } else {
      toast.error(
        "Vous ne pouvez sélectionner que des adresses à l'intérieur du Maroc."
      );
    }
  };

  const isLocationInsideMoroccoBounds = (lng, lat) => {
    return (
      lng >= moroccoBounds[0][0] &&
      lng <= moroccoBounds[1][0] &&
      lat >= moroccoBounds[0][1] &&
      lat <= moroccoBounds[1][1]
    );
  };

  // GOOGLE :
  const updateMarkerAndAddress = async (
    lng,
    lat,
    inputAddress,
    selectedLocation,
    mapInstance
  ) => {
    if (markerRef.current) markerRef.current.remove();
    mapInstance.flyTo({ center: [lng, lat], zoom: 18 });
    const newMarker = createCustomMarker([lng, lat]);
    newMarker.addTo(mapInstance || map);
    markerRef.current = newMarker;
    window.scrollTo({
      top: window.scrollY + 325,
      behavior: "smooth",
    });
    setZoomLevel(18);
    let resolvedAddress = inputAddress;
    let placeData = selectedLocation;
    if (!inputAddress) {
      const fetchedData = await getAddressFromLatLng(lng, lat);
      resolvedAddress = fetchedData?.formattedAddress;
      placeData = fetchedData?.placeData;
      if (!resolvedAddress || !placeData) {
        if (markerRef.current) markerRef.current.remove();
        setAddress("");
        return;
      }
    }
    setAddress(resolvedAddress);
    updateFormData(placeData, resolvedAddress, { lat, lng }, true);
  };
  // GOOGLE :
  const getAddressFromLatLng = async (lng, lat, check = false) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&language=fr&key=${apiKey}&result_type=street_address|political|locality|sublocality|postal_code|country`;
      const response = await axios.get(url);
      if (response?.data?.results?.length > 0) {
        const placeName = response?.data?.results[0]?.formatted_address || "";
        if (check && placeName) {
          setAddress(placeName);
          updateFormData(
            response?.data?.results[0],
            placeName,
            { lat, lng },
            true
          );
        }
        return {
          formattedAddress: placeName,
          placeData: response?.data?.results[0],
        };
      } else {
        toast.error("Aucune adresse trouvée pour ces coordonnées.");
      }
      return { formattedAddress: null, placeData: null };
    } catch (error) {
      console.error("Error fetching address from Google:", error);
      return { formattedAddress: null, placeData: null };
    }
  };

  const updateFormData = async (
    place,
    formattedAddress,
    selectedLocation,
    check
  ) => {
    // Create the form data object to update
    const updatedFormData = {
      address: formattedAddress,
      latitude: selectedLocation.lat,
      longitude: selectedLocation.lng,
      location: `${selectedLocation.lat},${selectedLocation.lng}`,
      geo_location: { lat: selectedLocation.lat, lon: selectedLocation.lng },
      street_check: check,
      region: null,
      region_code: null,
      country: "morocco",
      city: null,
      commune: null,
      province: null,
      quartier: null,
    };
    const regions = {
      oriental: "oriental",
      "casablanca-settat": "casablanca",
      "marrakesh-safi": "marrakech",
      "drâa-tafilalet": "draa",
      "fès-meknès": "fes",
      "rabat-salé-kénitra": "rabat",
      "béni mellal-khénifra": "beni",
      "souss-massa": "souss",
      "guelmim-oued": "guelmim",
      "laâyoune-sakia el hamra": "laayoune",
      "dakhla-oued ed-dahab": "dakhla",
      "tanger-tétouan-al": "tanger",
    };
    const cleanString = (str) => str.toLowerCase().replace(/[\s-]/g, "");
    if (place && place.address_components) {
      place.address_components.forEach((component) => {
        const componentName = component.long_name.toLowerCase();
        const componentTypes = component.types;
        if (componentTypes.includes("locality")) {
          updatedFormData.city = componentName;
        }
        if (componentTypes.includes("administrative_area_level_1")) {
          let regionKey = Object.keys(regions).find(
            (key) => key.toLowerCase() === componentName
          );
          if (!regionKey) {
            regionKey = Object.keys(regions).find((key) =>
              cleanString(componentName).includes(cleanString(key))
            );
          }
          if (regionKey) {
            updatedFormData.region = regionKey;
            updatedFormData.region_code = regions[regionKey];
          }
        }
        if (componentTypes.includes("administrative_area_level_2")) {
          updatedFormData.commune = componentName;
        }
        if (
          componentTypes.includes("sublocality") ||
          componentTypes.includes("neighborhood")
        ) {
          updatedFormData.quartier = componentName;
        }
        if (componentTypes.includes("administrative_area_level_2")) {
          updatedFormData.province = componentName;
        }
      });
    }
    if (!updatedFormData.region || !updatedFormData.region_code) {
      const placeNameLower = cleanString(formattedAddress);
      Object.entries(regions).forEach(([regionKey, regionValue]) => {
        const cleanedRegionKey = cleanString(regionKey);
        const cleanedRegionValue = cleanString(regionValue);
        if (
          placeNameLower.includes(cleanedRegionKey) ||
          placeNameLower.includes(cleanedRegionValue)
        ) {
          updatedFormData.region = regionKey;
          updatedFormData.region_code = regionValue;
        }
      });
    }
    const result = await estimationAddressMapping(
      {
        region: updatedFormData.region,
        city: updatedFormData.city,
        quartier: updatedFormData.quartier,
        province: updatedFormData.province,
        street_address: updatedFormData.street_check,
        lat: updatedFormData.latitude,
        long: updatedFormData.longitude,
      },
      postApiRequest
    );
    updatedFormData.region = result.region;
    updatedFormData.city = result.city;
    updatedFormData.quartier = result.quartier;
    updatedFormData.province = result.province;
    // console.log("final Data:", result);
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...updatedFormData,
    }));
  };

  const createCustomMarkerElement = () => {
    const markerElement = document.createElement("div");
    markerElement.style.position = "relative";
    markerElement.style.width = "90px";
    markerElement.style.height = "90px";
    const logoImage = document.createElement("img");
    logoImage.src = MapMarker;
    logoImage.style.position = "absolute";
    logoImage.style.top = "30%";
    logoImage.style.left = "52%";
    logoImage.style.transform = "translate(-50%, -50%)";
    logoImage.style.width = "100px";
    logoImage.style.height = "100px";
    const addressBox = document.createElement("div");
    addressBox.style.background = "white";
    addressBox.style.borderRadius = "4px";
    addressBox.style.padding = "8px";
    addressBox.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.2)";
    addressBox.style.position = "absolute";
    addressBox.style.bottom = "106px";
    addressBox.style.left = "50%";
    addressBox.style.transform = "translateX(-50%)";
    addressBox.style.textAlign = "center";
    addressBox.style.fontSize = "14px";
    addressBox.style.width = "250px";
    addressBox.innerText =
      "Glissez et déposez la broche pour définir l'emplacement";
    markerElement.appendChild(logoImage);
    markerElement.appendChild(addressBox);
    return markerElement;
  };

  const createCustomMarker = (coordinates) => {
    const markerElement = createCustomMarkerElement();
    return new mapboxgl.Marker(markerElement).setLngLat(coordinates);
  };

  const handleNext = () => {
    if (!address) {
      toast.error("L'adresse est requise.");
      return;
    }
    handleClick("next");
  };

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            // Use Google Maps API to fetch the address from the coordinates
            const addressData = await getAddressFromLatLng(longitude, latitude);

            if (addressData.formattedAddress) {
              // If the address is found, update the map and marker
              handleMapClick(
                longitude,
                latitude,
                map,
                addressData.formattedAddress
              );
            } else {
              toast.error("Impossible de récupérer l'adresse.");
            }
          } catch (error) {
            toast.error(
              "Erreur lors de la récupération de l'adresse : " + error.message
            );
          }
        },
        () => {
          toast.error(
            "Impossible d'obtenir votre position. Assurez-vous que votre GPS est allumé !"
          );
        }
      );
    } else {
      toast.info(
        "La géolocalisation n'est pas prise en charge par ce navigateur."
      );
    }
  };

  const changeStyle = () => {
    const currentStyle = map.getStyle();
    if (currentStyle.sprite.includes("clztg3vho00gb01qtayvohg5e")) {
      map.setStyle(
        "mapbox://styles/muhammadzeeshan9/cm1hcct2302so01pma9p7cmzx"
      );
      setMapStyle(false);
    } else {
      setMapStyle(true);
      map.setStyle("mapbox://styles/kifwatimmo/clztg3vho00gb01qtayvohg5e");
    }
  };

  return (
    <>
      <Box
        sx={{
          mt: 6,
          mb: 12,
        }}
      >
        <Grid container spacing={8} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
            >
              <Typography
                variant="body1"
                fontSize={24}
                fontWeight={600}
                color={"#263238"}
                gutterBottom
              >
                Adresse du bien
              </Typography>
              <Typography variant="body1" fontSize={16} color={"#263238"}>
                Entrez l'adresse du bien dans la barre de recherche.
              </Typography>
              <Box>
                {/* GOOGLE : */}
                <StepOneAutoComplete
                  address={address}
                  setAddress={setAddress}
                  handleSelect={handleAddressSelect}
                  isMobileIcons={!isMobileScreen}
                  markerRefFunction={() => {
                    if (isMobileScreen) {
                      // Check if markerRef.current is null on mobile
                      if (!markerRef.current) {
                        toast.error(
                          "Vous devez sélectionner une rue ou une adresse."
                        );
                      } else {
                        handleClick("next");
                      }
                    } else {
                      // Check if markerRef.current is null on desktop
                      if (!markerRef.current) {
                        toast.error(
                          "Vous devez sélectionner une rue ou une adresse."
                        );
                        window.scrollTo({
                          top: document.body.scrollHeight,
                          behavior: "smooth",
                        });
                      } else {
                        handleNext();
                      }
                    }
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              background:
                "url(https://s3-alpha-sig.figma.com/img/fd78/f384/914dcb7f51645e2d4e1fb92a043ad86f?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=lhaxnrPwnfegrKJoCsJV38B3PTwyuKTmfMsukC8wl4TCGDx~DYmebhqr4B8Tnt3X35u7r4aDM9vtxPxLOu0E8pNohqX997aOsQZAyYEx1Xqk0PDOPaeVhJFoCaPFsEgNikyC5jKdkD0cMiA6G1A1Dd3hABzalf7EKojWoa3jDzubXhcrEPfmITb7ttscCvS60DjqSVBmlPc3FDjudZlEBjtw65upcp1LlhWS3nWmsKjuQwcx222cbTNF1qSIzEeEeMwa1sgotprfp1DtoGmKaEdx00UyIJ5-wXG4QRwPvRPIYtMcFWqQsu~K~EX9FZPL5NDLzHYSw7xslBQJOjlm4A__) no-repeat center",
              backgroundRepeat: "round",
            }}
          >
            <PublishCard />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ paddingTop: "0 !important" }}
          >
            <Box width={"fit-content"} mt={6}>
              <Typography
                variant="body1"
                sx={{
                  my: 2,
                  cursor: "pointer",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#263238",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                  ":hover": {
                    color: "#0066EE",
                  },
                }}
                onClick={getUserLocation}
              >
                <TbCurrentLocation
                  size={20}
                  color="#0066EE"
                  style={{ marginRight: "8px" }}
                />
                Me géolocaliser
              </Typography>
            </Box>
            <Box>
              <Box
                ref={mapRefContainer}
                sx={{ height: "60vh", width: "100%", borderRadius: "16px" }}
              />
              <MapControls mapStyle={mapStyle} changeStyle={changeStyle} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        position="fixed"
        sx={{
          width: { xs: "100%", sm: "100%", md: "100%", lg: "83%" },
          top: "auto",
          bottom: 0,
          background: "#FFFFFF",
          borderTop: "1px solid #F1F5FE",
          zIndex: 9,
        }}
        elevation={0}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", padding: "10px 50px" }}
        >
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            variant="outlined"
            disabled={!markerRef.current}
            sx={{
              minWidth: { xs: "100%", sm: "100%", md: "150px", lg: "150px" },
              minHeight: { xs: "56px", sm: "56px" },
              px: { xs: 3, sm: 5 },
              fontFamily: `"Inter",sans-serif`,
              fontSize: 16,
              fontWeight: 500,
              borderRadius: "28px",
              border: "none !important",
              background: !markerRef.current ? "#DAE3EB" : "#0066EE",
              color: !markerRef.current ? "#DAE3EB" : "#FFFFFF",
              boxShadow: "none",
              "&:hover": {
                background: "#0B64DB !important",
                color: "#FFFFFF !important",
              },
            }}
            onClick={() =>
              !markerRef.current
                ? toast.error("Vous devez sélectionner une rue ou une adresse.")
                : handleNext()
            }
          >
            Suivant
          </Button>
        </Box>
      </Box>
    </>
  );
}
